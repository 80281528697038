/**
 * Tracking MailChimp Campaign parameters from the URL and storing them as cookies.
 *
 */

import { getCookie, setCookie } from "@/utils/cookies";
import Logger, { LogLevel }     from '@/utils/logger';

export class McTracking {
    public static readonly MC_PARAMS = ['cid', 'eid'];

    private static readonly MC_COOKIE_NAME = 'mailchimp';
    
    public static getMcCampaignId(): string | null {
        return getCookie(`${McTracking.MC_COOKIE_NAME}[cid]`);
    }

    private static getParameterByName(name: string): string {
        const match = window.location.search.match(new RegExp(`[\\?&]mc_${name}=([^&#]*)`));
        return match ? decodeURIComponent(match[1].replace(/\+/g, " ")) : "";
    }

    private static setMcCookie(mcParam: string, mcVal: string): void {
        if (mcVal) {
            Logger.log(LogLevel.DEBUG, 'Set the cookie');
            setCookie(mcParam, mcVal);
        }
    }

    static init(): void {
        if (!window.location.search || !window.location.search.includes('mc_')) {
            return;
        }

        for (const param of McTracking.MC_PARAMS) {
            const value = McTracking.getParameterByName(param);
            McTracking.setMcCookie(`${McTracking.MC_COOKIE_NAME}[${param}]`, value);
        }
    }
}

McTracking.init();
